import type { LinksFunction, MetaFunction } from "react-router";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "react-router";
import ErrorPage from "~/pages/misc/error";
import NotFoundPage from "~/pages/misc/not-found";
import { TooltipProvider } from "./components/ui/tooltip";
import styles from "./tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto+Slab:wght@100..900&display=swap",
  },
  { rel: "icon", type: "image/svg+xml", href: "/favicon@2x.png" },
  { rel: "stylesheet", href: styles },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    // suppressHydrationWarning is needed to avoid hydration error when the theme is set via theme.js
    <html
      lang="en"
      suppressHydrationWarning={true}
      className="scrollbar-thin scrollbar-thumb-foreground/20 scrollbar-track-foreground/5"
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {/* set theme immediately to avoid flashing after first time load, see also with-user.tsx */}
        <script src="/theme.js" />
      </head>
      <body>
        <TooltipProvider>{children}</TooltipProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  if (error && typeof error === "object" && "status" in error && error.status === 404) {
    return <NotFoundPage />;
  }
  return <ErrorPage />;
}

export default function Root() {
  return <Outlet />;
}

export const meta: MetaFunction = () => {
  return [
    { title: "Sumcast – Discussions, summarized" },
    {
      name: "description",
      content:
        "Sumcast distills Slack conversations, Whimsical posts, Reddit discussions and more into a concise daily newsletter that only takes a few minutes to read. Or, listen to it in your favorite podcast app.",
    },
  ];
};
